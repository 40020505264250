import React, { Component } from 'react';
import PropTypes from "prop-types";
import parse from 'html-react-parser';
import VideoModal from '../reuseable/VideoModal'

export default class EventHead extends Component {
   state = {
      upcoming_event: true,
      is_active: false,
   }

   componentDidMount() {
      this.getDate();
   }

  getDate = () =>{
      var q = new Date();
      var m = q.getMonth();
      var d = q.getDate();
      var y = q.getFullYear();
      const today = new Date(y,m,d);
      const mydate = new Date(this.props.card_data.date_compare);
      if(today > mydate)
         {
            this.setState({upcoming_event: false})
         }
   }   

  onClose = () => {
      this.setState({is_active: false});
   }

   openVideo = () =>{
      this.setState({is_active: true});
   }

   formSubmit = () =>{
      var element = document.getElementById("event-subs-box");
      element.classList.add("is-hidden");
      var element1 = document.getElementById("event-page-thanks-msg");
      element1.classList.add("is-block");
   }

   render(){
      const {card_data} = this.props;
      const{upcoming_event, is_active} =this.state;
      return(  
      <div className="pd-80 event-page-innerPadding">
         <div className="has-text-centered-mobile">
            {upcoming_event?
            <strong className="is-size-3  is-size-4-mobile">Upcoming Event</strong>
            :<strong className="is-size-3  is-size-4-mobile">No Upcoming Event</strong>
            }
         </div>
      <div className="card card-shadow event-page-mainImg "> 
       {
         (card_data.image && upcoming_event && card_data.mobileImg) ?     
            <div className="card-image ">
               <figure className="image">
               <img src={card_data.image} className="radius-top-10 is-hidden-mobile " alt="Placeholder"/>
               <img src={card_data.mobileImg} className="radius-top-10  is-hidden-tablet  " alt="Placeholder"/>
               </figure>
            </div> : <div>
            { (!upcoming_event && card_data.no_upcoming_event_img && card_data.no_upcoming_event_mobile_img)?
             <div className="card-image event-card-hoverup-tablet">
             <figure className="image">
             <img src={card_data.no_upcoming_event_img} className="radius-top-10 is-hidden-mobile " alt="Placeholder" onClick= {this.openVideo}/>
             <img src={card_data.no_upcoming_event_mobile_img} className="radius-top-10  is-hidden-tablet  " alt="Placeholder" onClick= {this.openVideo}/>
             </figure>
             {is_active ? <VideoModal video_id={card_data.no_upcoming_event_link} onClose={this.onClose} is_active={is_active}/> : null}
            </div> : null
             }        
            </div>
       }
     </div>
     {upcoming_event?
     <div className="card card-shadow  event-page-infoCard pd-left-mobile-40 column is-flex-tablet "> 
         <div className=" column is-one-quarter ">
            <strong className="font-14 event-page-venueColor is-family-poppins">Place</strong>
            <div>
                <h4 className="font-17 is-family-poppins line-height-24 mg-top-10"> {card_data.place}</h4>
            </div>
         </div>
         <div className=" column is-one-fifth ">
            <strong className="font-14 event-page-venueColor is-family-poppins" >Date</strong>
            <div>
            <h4 className="font-17 is-family-poppins line-height-24 mg-top-10"> {card_data.date}</h4>
            </div>
         </div>
         <div className=" column is-two-fifths ">
            <strong className="font-14 event-page-venueColor is-family-poppins">TIME</strong>
            <div>
            <h4 className="font-17 is-family-poppins line-height-24 mg-top-10"> {card_data.time}</h4>
            </div>
         </div>
         <div className=" column is-fifteen-percent event-page-btnDiv">
            <button className="button event-page-btn is-link " onClick = {event =>  window.open(card_data.link)}>Register</button>
         </div>
     </div>
     :<div className="card card-shadow  event-page-infoCard column is-flex-desktop "> 
      <div className=" column  ">
         <div>
             <h4 className="font-17 is-family-poppins line-height-24 mg-top-10"> {parse(card_data.no_upcoming_event_text)}</h4>
         </div>
      </div>
      <div className=" column event-page-btnDiv">
      <form  id="event-subs-box" onSubmit={this.formSubmit} action="https://forms.hubspot.com/uploads/form/v2/5722254/fa48bd67-b00e-4c0c-826b-5f95bbe76edb" method="post" name="sign up for beta form">
      <div className="is-flex" >
        <input type="email" className="event-page-emailBox" name="email" placeholder="Work Email ID" required/>
        <input type="submit" className="button event-page-btn is-link event-page-subsBtn" id="submit" value="Sign Up"/>
        </div>
    </form>
      <div className="success-mes">
        <span id="event-page-thanks-msg"> Thanks For Subscribing </span>
      </div>
      </div>
   </div>
   }
   </div>
   )
   } 
}
EventHead.propTypes = {
    card_data: PropTypes.object,
}
